import Testimonial from '../components/testimonial';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import React, { useEffect } from 'react';
function Maintenance(){
  const testimonialProps1 = {
    title: "Revitalizing Website Maintenance and Speed",
    imageSrc: "images/dte_1.png",
    testimonialText: "We had the pleasure of working with Uniwork Technologies on website maintenance. Their dedication and expertise in website maintenance were truly commendable. We look forward to continued collaboration with Uniwork Technologies.",
  };

  const testimonialProps2 = {
    title: "Landing Page Design & Implementation",
    imageSrc: "images/dte_2.png",
    testimonialText: "Our partnership with Uniwork Technologies for Website Maintenance has been truly remarkable. They've consistently demonstrated exceptional dedication and expertise in keeping our website in top shape. We eagerly anticipate further collaboration with Uniwork Technologies to ensure our online presence remains impeccable.",
  };

  const testimonialProps3 = {
    title: "Wordpress calendly and stripe integration expert ",
    imageSrc: "images/dte_3.png",
    testimonialText: "Within the realm of Website Maintenance, we've found a steadfast partner in Uniwork Technologies. Their relentless commitment and technical finesse have consistently elevated our website's performance. Our path ahead with Uniwork Technologies is paved with excitement as we ensure the enduring strength of our online presence.",
  };

  const testimonialProps4 = {
    title: "Website project",
    imageSrc: "images/dte_4.png",
    testimonialText: "Through the dynamic landscape of Website Maintenance, we've found a steadfast partner in Uniwork Technologies. Their unwavering dedication and expertise have been pivotal in enhancing our website's performance. As we look ahead, Uniwork Technologies remains our cornerstone for maintaining a strong and thriving digital footprint.",
  };
  useEffect(() => {
    window.scrollTo(0, 0); // Ensure the page starts at the top when the component mounts.
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
  };
    return(
        <div className="mainn">
    <Helmet>
        <title>
        Uniwork Technologies: Website Maintenance Services Experts</title>
  
<meta name='description' content='Enhance your web performance with our website maintenance services. Experts manage updates & security for an online presence. Contact Uniwork Technologies now.' />
      </Helmet>
<section style={{ paddingTop: '1px'}}>
  <div className="container-fluid">
    <Fade up>
     <div className="websec_1 servcemx_wdth2 sec_3  mt-90 text-center pb-40">
                <h1>Website<span style={{ color: '#223464' }}>  Maintenance</span> </h1>
      <p>We ensure that your website is current, operating faultlessly, functioning at its peak, free of technical problems, enhancing User Experience, and Maintaining a high ranking in search engine results pages. We optimize your website's functionality to enhance its responsiveness and quickness. We routinely scan the website to find and fix security flaws before they can do any harm. </p>
  </div>
  </Fade>
    </div>
</section>


<section>
  <div className="pb-90"> 
     <img src="images/mntbnr.webp" width="100%" alt="Our Website Maintenance Services"/>
   </div>
</section>

<section>
  <div className="container-fluid">
    <div className="websec_3 sec_3 mt-3 text-center">
      <h2>Our<span style={{ color: '#223464' }}> Website Maintenance Services</span></h2>
    </div>
    <div className="container websec_3 sec_3 mt-5">
      <div className="row">
        <Zoom>
        <div className="col">
          <div className="box_1 mntbox_1" style={{ backgroundColor: '#F2F1F1' }}>
            <img src="images/mnt1.webp" width="90" alt="Automated Maintenance"/>
            <h3>Automated Maintenance</h3>
            <p>Let Us Take Care of Your Website for You" - We offer Automated Website Maintenance services that take the hassle out of keeping your website up-to-date and secure.</p>
          </div>
        </div>
        </Zoom>
        <Zoom>
        <div className="col">
          <div className="box_1" style={{ backgroundColor: '#F2F1F1' }}>
            <img src="images/mnt2.webp" width="90" alt="Performance Optimization"/>
            <h3>Performance Optimization</h3>
            <p>Keep Your Website Running at Peak Performance" - Our Website Maintenance services include Performance Optimization to ensure that your website is running smoothly and loading quickly for your visitors.</p>
          </div>
        </div>
        </Zoom>
        <Zoom>
        <div className="col">
          <div className="box_1" style={{ backgroundColor: '#F2F1F1' }}>
            <img src="images/mnt3.webp" width="90" alt="Security Updates"/>
            <h3>Security Updates</h3>
            <p>Keep Your Website Protected from Threats" - We provide regular Security Updates to keep your website protected from the latest threats and vulnerabilities, giving you peace of mind that your website is safe and secure.</p>
          </div>
        </div>
        </Zoom>
        <div className="ttl_ss_des"><p>Are you ready to keep your website performing at its best? <Link to="/">Uniwork Technologies</Link> is here. Our <Link to="/website-maintenance" onClick={scrollToTop}>Web Maintenance Services</Link> ensure your site stays current, error-free, and user-friendly, all while preserving high search engine rankings. If you're seeking more <Link to="/web-development">Website Development</Link>, <Link to="/web-design">Web Designing</Link>, and <Link to="/graphic-design">Graphic Design Services</Link>, don't hesitate to contact us. Let's enhance your digital platform – reach out today!</p></div>
      </div>
    </div>
  </div>
</section>
<section className="bg-light services_callsec mt-90">
      <div className="container">
        <div className="row align-items-center">  
          <div className="col-md-6">
            <div>
              <h2>Schedule a call</h2>
              <h2>now with our</h2>
              <h2>web maintenance</h2>
              <h2>expert!</h2>
            </div>
            <div className='btn_areaservsec'>
              <img src='images/right_arrow.png'/>
              <button className="btn_services shed_call">
      <Link to="https://wa.me/06239452423" target='_blank'>Contact Us</Link>
    </button>
            </div>
          </div>
          <div className="col-md-6">
            {/* <img src="images/smiling-woman-headset-presentation-something.png" alt="Background Image" className="img-fluid" /> */}
          </div>
        </div>
      </div>
    </section>
     <div>
    <section className="mt-90 pb-90 abt_last7 ssx_str mx-4" aria-label='Ut back'>
<div className="mx_wdth2 sec_3 mt-90 pb-90 text-center">
<p className="svr_12 pt-3">- TESTIMONIAL -</p>
<h2>See What People Say<span style={{ color: '#223464' }}> About Us</span></h2>
</div>
      <div id="carouselExampleIndicators" className="carousel slide">
        <div className="carousel-inner abt-tsti1">
          <div className="carousel-item active p-4">
            <Testimonial {...testimonialProps1} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps2} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps3} />
          </div>
          <div className="carousel-item p-4">
            <Testimonial {...testimonialProps4} />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"><img src="images/lft.webp" alt="Breadcrumb Left"/></span>
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"><img src="images/rgt.webp" alt="Breadcrumb Right"/></span>
      <span className="visually-hidden">Next</span>
    </button>
      </div>
      </section>
      </div>
        </div>
    );
}

export default Maintenance;