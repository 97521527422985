import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

function Home() {
  const [expandedCards, setExpandedCards] = useState([]);

  const toggleText = (index) => {
    if (expandedCards.includes(index)) {
      setExpandedCards(expandedCards.filter((cardIndex) => cardIndex !== index));
    } else {
      // Close any previously expanded cards
      setExpandedCards([index]);
    }
  };

    return(
      
        <div classNamme="mainn">
            <Helmet>
        <title>        
Uniwork Technologies: Digital Marketing & Web Design Agency</title>

<meta name='description' content=' Elevate your digital journey with Uniwork Technologies. We offer Web Design/Development and Digital Marketing Services including SEO, PPC, Web Analytics & more.' />
      </Helmet>
            <section className="pt-2">
   <div className="container-fluid">
      <div className="row banrback_1 sec_1 mt-90" aria-label='Achieving Online Marketing Excellence and Growth'>
         <div className="col-6 bnrtxt_1">
          
             <h1><span style={{ color: '#223464' }}>Drive Success with</span><br /> Our Digital Marketing <br />&  Web Design Expertise</h1>
                 <button className="hdrbtn_1 hdrbtn_3"><Link to="/services">Learn More</Link></button>
                 
         </div>
         <Fade right>
     <div className="col-6 banner_img1">
        <img src="Images/banner.webp" alt="Digital Marketing & Web Design Strategies" />
     </div>
     </Fade>
    </div> 
  </div>
</section>

<section  className="sec_2  mt-90 bg-image text-center" aria-label='We Are Uniwork'>
<div className="video-wrapper">
    <video autoPlay loop muted playsinline="playsinline">
      <source src="images/about.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div className="overlay"></div>
    </div> 	
    <Fade bottom>
    <div className="bdtxt_12 mx_wdth2 text-white txt_w">
				<h2>We Are Uniwork</h2>
			<img src="images/border.webp" alt="Border"/>
<p>We assist in building up better communication between people, and companies. We tend to make the world a better place, hoping that it will bring up the best in each of us.</p>
<button className="hdrbtn_1 hdrbtn_2"><Link to="/about">Read More</Link></button>
</div>
</Fade>
			
</section>




<section>
    <div className="container-fluid">
        <div className="mx_wdth2 sec_3  mt-90 text-center">
            <p class="svr_12">- SERVICES -</p>
            <h2>What <span style={{ color: '#223464' }}>We Do?</span> </h2>
            <p>All of the resources you need under one roof. Your Website is your online business card – let’s make it one to remember and convert into profit with our Website Design expertise.</p>
        </div>
        <Fade left>
        <div id="cards_landscape_wrap-2">
            <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                    <div className="card-flyer">
                        <div className="text-box">
                            <div className="image-box">
                                <img src="images/imgbox1.webp" alt="Web Designing Services" />
                            </div>
                            <div className="text-container">
                                <img className="smimgsrv_1" src="images/ssm1.webp" alt="Web Designing"/>
                                <Link to="/web-design"><h3>Web Designing</h3></Link>
                                <p>We provide top-notch Web Designing services to businesses of all sizes. We understand the importance of having a well-designed Website that not only looks great but also functions {expandedCards.includes(11) ? (  <> seamlessly. Our team of skilled Web Designers works closely with clients to create customized Websites that meet their unique needs and requirements. We are dedicated to delivering exceptional results that help our clients stand out in today's competitive online marketplace. From simple static Websites to complex E-commerce platforms, we have the expertise to deliver high-quality Web Designs that help businesses succeed online.
                                 </>
                                 ) : (
                                   <>
                                     <span id="dots"></span>
                                     <span className="more">...</span>
                                   </>
                                 )}
                               </p>
                               <button onClick={() => toggleText(11)} className="toggle-btn">
                                 {expandedCards.includes(11) ? 'Read less' : '....Read more'}
                               </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                    <div className="card-flyer">
                        <div className="text-box">
                            <div className="image-box">
                                <img src="images/imgbox2.webp" alt="Web Development Services" />
                            </div>
                            <div className="text-container">
                                <img className="smimgsrv_1" src="images/ssm2.webp" alt="Professional Web Development Services"/>
                                <Link to="/web-development"><h3>Web Development</h3></Link>
                                <p>Our company offers professional Web Development services to help businesses establish a strong online presence. Our experienced team of Web Developers {expandedCards.includes(12) ? (    <>    specializes                     
                                 in creating custom Websites that are tailored to meet the unique needs of our clients. We use the latest Web Development technologies and best practices to ensure that our clients' Websites are not only visually appealing but also highly functional, responsive, and user-friendly. From E-commerce platforms to Content Management Systems, our Web Development services cover a wide range of solutions to help our clients achieve their business objectives. Contact us today to learn how we can help you enhance your online presence and drive more traffic to your Website.
                                 </>
                                 ) : (
                                   <>
                                     <span id="dots"></span>
                                     <span className="more">...</span>
                                   </>
                                 )}
                               </p>
                               <button onClick={() => toggleText(12)} className="toggle-btn">
                                 {expandedCards.includes(12) ? 'Read less' : '....Read more'}
                               </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                     <img src="images/digitalmarkiting.webp" alt="Digital Marketing Services" />
                                </div>
                                <div className="text-container">  
                                   <img className="smimgsrv_1" src="images/ssm3.webp" alt="Comprehensive Digital Marketing Services"/>                                  
                                   <Link to="/digital-marketing-agency"><h3>Digital Marketing</h3></Link>
                                    <p> We offer comprehensive Digital Marketing services to help businesses succeed online. Our services include Search Engine Optimization (SEO), Website and Data Analytics,                                 
                                        Pay-Per-Click{expandedCards.includes(13) ? (                                <> (PPC) advertising,  Search Engine Marketing (SEM), Conversion Rate Optimization (CRO), Social Media Marketing (SMM), Email Marketing, and more. We tailor our strategies to each client's unique goals and utilize data-driven approaches for optimal results. Whether you're a small business or a large enterprise, we can enhance your online presence, increase brand visibility, and drive meaningful outcomes. Partner with us to achieve your Digital Marketing objectives.
                                    </>
                          ) : (
                            <>
                              <span id="dots"></span>
                              <span className="more">...</span>
                            </>
                          )}
                            </p>
                            <button onClick={() => toggleText(13)} className="toggle-btn">
                          {expandedCards.includes(13) ? 'Read less' : '....Read more'}
                        </button>
                                </div>
                            </div>
                        </div>
                   </div>  
            </div>
        </div>
        </Fade>
        <Fade right>
        <div id="cards_landscape_wrap-2">
            <div className="mt-4  cnt_2">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                    <img src="images/imgbox4.webp" alt="Graphic Designing Services" />
                                </div>
                                <div className="text-container">
                                    <img className="smimgsrv_1" src="images/ssm4.webp" alt="Professional Graphic Designing Services"/>
                                   <Link to="/graphic-design"> <h3>Graphic Designing</h3></Link>
                                    <p>Looking for professional Graphic Designing services? Our company has got you covered. Our team of skilled designers is dedicated to delivering customized solutions that {expandedCards.includes(14) ? (    <>   
                                       cater to  the unique needs of your business. We use Cutting-Edge tools and techniques to create Visually Stunning designs that capture the essence of your brand. From Logo Design to Website Development and marketing collateral, we provide end-to-end solutions that help you stand out from the competition. Our focus on Creativity, Attention to detail, and commitment to excellence ensures that we deliver High-Quality designs that exceed your expectations. Get in touch with us today to learn more about our services and how we can help your business succeed.
                                    </>
                                 ) : (
                                   <>
                                     <span id="dots"></span>
                                     <span className="more">...</span>
                                   </>
                                 )}
                               </p>
                               <button onClick={() => toggleText(14)} className="toggle-btn">
                                 {expandedCards.includes(14) ? 'Read less' : '....Read more'}
                               </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                    <img src="images/imgbox5.webp" alt="Website Maintenance" />
                                </div>
                                <div className="text-container">
                                    <img className="smimgsrv_1" src="images/ssm5.webp" alt="Website Maintenance Services"/>
                                    <Link to="/Website-maintenance"><h3>Website Maintenance</h3></Link>
                                    <p>We ensure that your Website is current, operating faultlessly, functioning at its peak, free of technical problems, enhancing User Experience, and Maintaining a high ranking{expandedCards.includes(16) ? (    <>   in Search Engine
                                     results pages. We optimize your Website's functionality to enhance its responsiveness and quickness. We routinely scan the Website to find and fix security flaws before they can do any harm.
                                    </>
                                 ) : (
                                   <>
                                     <span id="dots"></span>
                                     <span className="more">...</span>
                                   </>
                                 )}
                               </p>
                               <button onClick={() => toggleText(16)} className="toggle-btn">
                                 {expandedCards.includes(16) ? 'Read less' : '....Read more'}
                               </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                        <div className="card-flyer">
                            <div className="text-box">
                                <div className="image-box">
                                    <img src="images/imgbox6.webp" alt="Website Development" />
                                </div>
                                <div className="text-container">
                                    <img className="smimgsrv_1" src="images/ssm6.webp" alt="Customized Templates"/>
                                    <Link to="/Website-template"><h3>Template</h3></Link>
                                    <p>Our team of experienced designers and developers work closely with clients to create customized templates that not only look great but also drive engagement and {expandedCards.includes(15) ? (    <>   conversions.  We understand that every business has unique needs, which is why we offer a wide range of template options to choose from or can create a completely custom design tailored to your specific requirements. Our templates are optimized for all devices and email clients, ensuring that your messages always look professional and polished. With our Email Template services, you can streamline your communication efforts and take your email marketing to the next level.
                                     </>
                                 ) : (
                                   <>
                                     <span id="dots"></span>
                                     <span className="more">...</span>
                                   </>
                                 )}
                               </p>
                               <button onClick={() => toggleText(15)} className="toggle-btn">
                                 {expandedCards.includes(15) ? 'Read less' : '....Read more'}
                               </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </Fade>
       <div className="text-center mb-5">
            <button className="hdrbtn_1 hdrbtn_3"><Link to="/services">See More</Link></button>
        </div>
    </div>
</section>






<section>
	<div className="sec4 prcs_1 mt-90">
  <div className="video-wrapper">
    <video autoPlay loop muted playsinline="playsinline">
      <source src="images/process.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div className="overlay"></div>
    </div> 
<div className="bdtxt_12  container-fluid">
<h2 className="text-white text-center">The Process</h2>
<div className="row">
	<div className="col-sm-4 inr4sct_1">
<img src="images/numbr1.webp" width="50px" height="50px" alt="The Process 1" />
<p>Explore our services and give us a call to consult what services are right for you.</p>
</div>

<div className="col-sm-4 inr4sct_1">
<img src="images/numbr2.webp" width="50px" height="50px" alt="The Process 2" />
<p>Once the proposal is approved by you, our team will kickstart your project.</p>
</div>
<div className="col-sm-4 inr4sct_1">
<img src="images/numbr3.webp" width="50px" height="50px" alt="The Process 3" />
<p>You will be updated throughout every step of your project & once the project is ready for approval.</p>
</div>
</div>
</div>
</div>
</section>

<section>
<div className="container-fluid">
<div className="mx_wdth2 sec_3  mt-90 text-center">
<p class="svr_12">- PROJECTS -</p>
				<h2>Recent <span style={{ color: '#223464' }}>Completed Works</span> </h2>
			</div>

<div className="row sec5">
      <div className="col-md-3">
        <div className="portfolio-item">       
         <div className="alll">         
         	 <img src="images/prt1.webp" className="img-fluid" alt="Project: Raloha" />
         	<div className="btnnn"><Link to="https://raloha.com/" target="_blank" rel="noopener"> Quick View</Link></div>
         </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="portfolio-item">
          <div className="alll">
         	 <img src="images/prt2.webp" className="img-fluid" alt="Project: ShipRx" />
         	<div className="btnnn"><Link to="https://shiprx.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="portfolio-item">
         <div className="alll">
         	 <img src="images/prt3.webp" className="img-fluid" alt="Project: Afterlight Leisure" />
         	<div className="btnnn"><Link to="https://afterlightleisure.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="portfolio-item">
         
         <div className="alll">
         	 <img src="images/prt4.webp" className="img-fluid" alt="Project: Flook The Label" />
         	<div className="btnnn"><Link to="https://flookthelabel.com/" target="_blank" rel="noopener"> Quick View</Link></div>

         </div>
        </div>
      </div>

<div className="text-center mt-5">
	<button className="hdrbtn_1 hdrbtn_3"><Link to="/portfolio">View All Projects</Link></button>
</div>

    </div>
  </div>
</section>

<section>
<div className="container-fluid sec_6">
	<div className="row  mt-90">
	
<div className="col-sm-6 order-xl-1 order-2 mt-xl-0 mt-4">
	<h2 className="gols_1">Our <span style={{ color: '#223464' }}>Goals</span> </h2>
	<p className="gols_2">When it comes to the question of a perfect Website the most important thing is responsiveness. A fully responsive Website makes the possibility to interact with a site from any device. The clean typography and easy-to-read content give the users experience of the content itself. Good navigation works by simply scrolling down the page so that it helps the users to find the content they are looking for and it offers a positive experience that will encourage them to return.</p>
</div>
<div className="col-sm-6 text-end  order-xl-2 order-1">
	<img src="images/sec6.webp" width="80%" alt="Our Goals" />
</div>
</div>
	<div className="row inr6">
<div className="col-sm-6 text-start">
	<img src="images/sec6-2.webp" width="80%" alt="Uniwork Technologies Result" />
</div>
<div className="col-sm-6 mt-4">
    <div className="gol_3">
	<h2 className="gols_1">The <span style={{ color: '#223464' }}> Result</span> </h2>
	<p className="">The feeling of creativity is perceived in every corner of the site. The aesthetic design pushes usability and interactivity. The team has taken care not only of the spectacular design but also of the functionality. The result is that the uniwork team has managed to implement all the wishes of the client and important aspects for a good Website.</p>
</div>
</div>
</div>
	</div>
</section>

<section>
<div className="hpclnt_1 container-fluid mt-90">
	<h2>Our <span style={{ color: '#223464' }}>Happy Clients</span> </h2>
	<p>We believe in going an extra mile for our clients </p>
<div className="imgbx_1 mt-5">
<div className="sitimg_1">
 <img src="images/s7-1.webp" alt="Innovative Researchers" />
</div>
<div className="sitimg_1">
  <img src="images/s7-2.webp" alt="Curbside Laundries" />
</div>
<div className="sitimg_1">
  <img src="images/s7-3.webp" alt="Afterlight Leisure" />
</div>
<div className="sitimg_1">
  <img src="images/s7-4.webp" alt="Incredo" />
</div>
<div className="sitimg_1">
  <img src="images/s7-5.webp" alt="Wattle & Wax" />
</div>
<div className="sitimg_1">
  <img src="images/s7-6.webp" alt="Shehzada Fahad" />
</div>
</div>
</div>
</section>

<section>
<div className=" pb-90 tst_21 abt_last7" style={{ backgroundImage: "url('images/bglst.webp')" }} aria-label='Ut back'>
<div className="container-fluid mt-90">
	<div className="mx_wdth2 sec_3  mt-90 text-center">
		<div className="row lstbnr_1 mt-90" style={{ backgroundImage: "url('images/bglst.webp')" }} aria-label='Ut back'>
		<p class="svr_12">- TESTIMONIAL -</p>
				<h2>See What People Say <span style={{ color: '#223464' }}> About Us</span> </h2>
			</div>

</div>


 <div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
        <div className="carousel-inner mx_wdth2 sec_8">
            <div className="carousel-item crl-itembg active">
                <div className="text-left tst_1 tt_2 text-white">
                    <i>“Working with Uniwork Technologies was a great experience. Their advertising campaigns were expertly crafted. It showcased their outstanding skills and unwavering professionalism, and I would not hesitate to work with them again. I wholeheartedly recommend Uniwork Technologies to anyone looking for exceptional advertising campaigns. Thank you for the fantastic work!”</i>
                    
                </div>
                    <div className="namesct_1">           	
                                <div className="name_1 d-flex justify-content-end">                 	
                    <span></span>
                    <p>Valters L.</p>
                    <img src="images/prfl1.webp" alt='border_barnpm' />             
                    </div>
            </div>
            </div>
            <div className="carousel-item crl-itembg">
                <div className="text-left tst_1 tt_2 font-italic text-white">
                    <i>"Uniwork Technologies provided 100% solutions to my issues. I highly recommend them for any kind of WordPress development work. Their problem-solving skills are amazing."</i>
                </div>
                <div className="namesct_1">            
                             <div className="name_1 d-flex justify-content-end">                    
                             <span></span>
                 <p>June</p>
                 <img src="images/prfl1.webp" alt="Profile Icon"/>             
                </div>
         </div>
            </div>
            <div className="carousel-item crl-itembg">
                <div className="text-left tst_1 tt_2 font-italic text-white">
                   <i>“Uniwork Technologies is a great company to work with – they are very reliable, knowledgeable, and proficient. They helped us with everything from setting up a new Google Data Studio to verifying that our attribution tracking across multiple clients was correct. I absolutely recommend Uniwork Technologies!”</i>

                </div>
               <div className="namesct_1">             
                             <div className="name_1 d-flex justify-content-end">                    
                             <span></span>
                 <p>Peter</p>
                 <img src="images/prfl1.webp" alt="Profile Icon"/>             
                </div>
         </div>
            </div>
            <div className="carousel-item crl-itembg">
                <div className="text-left tst_1 tt_2 font-italic text-white">
                   <i>"It was a pleasure to work with Uniwork Technologies. They communicated frequently and replied to messages promptly. They completed the project within the estimated timeframe. I will definitely hire Uniwork Technologies again for future projects."</i>

                </div>
               <div className="namesct_1">             
                             <div className="name_1 d-flex justify-content-end">                    
                             <span></span>
                 <p>Shehzad f.</p>
                 <img src="images/prfl1.webp" alt="Profile Icon"/>             
                </div>
         </div>
            </div>
        </div>
        
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching"
            data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"><img className="rgt" src="images/rgt.webp" alt="Breadcrumb Right"/></span>
            <span className="visually-hidden">Next</span>
        </button>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching"
            data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"><img className="rgt" src="images/lft.webp" alt="Breadcrumb Left" /></span>
            <span className="visually-hidden">Previous</span>
        </button>
    </div>

 <div className="row homelstbx_1 mt-5 d-blk">
  <Zoom>
      <div className="col text-center">      
        <div className="custom-border">
         <img src="images/l8-1.webp" width="30px" alt="8+ Years Of Experience"/>
         <p class="exp_41">8+</p>
       <p>YEARS OF EXPERIENCE</p>
        </div>       
      </div>
      </Zoom>
      <Zoom>
      <div className="col text-center">      
        <div className="custom-border">
       <img src="images/l8-2.webp" width="30px" alt="200+ Projects Completed"/>
       <p class="exp_41">200+</p>
       <p>PROJECTS COMPLETED</p>
        </div>        
      </div>
      </Zoom>
      <Zoom>
      <div className="col text-center">      
        <div className="custom-border">
         <img src="images/l8-3.webp" width="30px" alt="Happy Clients"/>
         <p class="exp_41">90+</p>
       <p>HAPPY CLIENTS</p>
        </div>        
      </div>
      </Zoom>
      <Zoom>
      <div className="col text-center">      
        <div className="custom-border">        
        <img src="images/l8-4.webp" width="30px" alt="Support Hours"/>
        <p class="exp_41">1250</p>
       <p>HOURS OF SUPPORT</p>       
        </div>
      </div>
      </Zoom>
    </div>

</div>
</div>
</section>
        
        </div>
    );
}

export default Home;